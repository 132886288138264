<template>
  <v-card :loading="Object.keys(data).length == 0">
    <v-container v-if="Object.keys(data).length > 0" fluid>
      <div class="d-flex align-start flex-wrap mb-4" style="gap: 16px">
        <template v-if="!data.plan_de_pagos">
          <div
            style="max-width: 800px"
            class="d-flex flex-column flex-grow-1 mx-auto"
          >
            <div class="d-flex justify-space-around align-start flex-wrap">
              <v-alert type="warning" text
                >Esta factura no tiene Plan de Pagos</v-alert
              >
              <v-alert class="ma-0" type="info" text>
                <b>Importe factura</b>: {{ data.ImporteTotal }}€</v-alert
              >
            </div>
            <div class="d-flex align-center">
              <v-btn
                @click.stop="enviarPlanPago"
                class="ml-auto"
                large
                color="secondary"
              >
                Crear Plan de Pagos
              </v-btn>
            </div>
          </div>
        </template>

      </div>
    </v-container>
  </v-card>
</template>

<script>
import { parseImpagados } from "../services/parseImpagados.js";
import { req } from "@/utils/validations.js";
import { parseDate } from "@/utils/index.js";
import Dinero from "dinero.js";
Dinero.globalLocale = "es-ES";

export default {
  data() {
    return {
      rules: { req },
      creditoSolicitado: null,
      data: {},
      desc: null,
      sending: false,

      pagos: [],
      Dinero,

      editable : 1,
    };
  },
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
  },
  props: {
    IdCarteraCobro: String | Number,
  },
  computed: {
    nPagos() {
      return this.pagos.length;
    },
    totalPagos() {
      let total = new Dinero({ amount: 0, currency: "EUR" });
      for (const pago of this.pagos) {
        total = total.add(pago.importe);
      }
      return total;
    },
    isImporteCompleto() {
      if (!this.data || !this.data.ImporteTotal) return false;
      return this.totalPagos.equalsTo(
        new Dinero({ amount: this.data.ImporteTotal * 100, currency: "EUR" })
      );
    },
  },
  methods: {
    parseDate,
    cambioEstadoPago(pago) {
      this.$set(pago, 'pagado', !pago.pagado);

       axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/plan_pagos/cambio_estado_pago.php`,
        method: "POST",
        data: {
          token: this.$store.getters.getJwtEmpresa,
          IdPago : pago.IdPago,
        },
      })
    },
    enviarPlanPago() {
      this.sending = true;

      axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/plan_pagos/crearPlanPago.php`,
        method: "POST",
        data: {
          token: this.$store.getters.getJwtEmpresa,
          IdCarteraCobro: this.IdCarteraCobro,
          desc: this.desc,
          pagos: this.pagos.map( p => ({
            fecha : new Date(p.fecha).toISOString(),
            importe : p.importe.getAmount() / 100
          }))
        },
      })
        .then(({ data }) => {
          this.$root.$emit("snack", "Se ha creado el plan de pagos");
          this.getDatos();
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido crear el plan de pagos");
        })
        .finally(() => (this.sending = false));
    },
    async getDatos() {
      const { data} = await axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
        method: "GET",
        params: {
          token: this.$store.getters.getJwtEmpresa,
          IdCarteraCobro: this.IdCarteraCobro,
        },
      })

      const { data : plan } = await axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/plan_pagos/get_plan.php`,
        method: "GET",
        params: {
          token: this.$store.getters.getJwtEmpresa,
          IdCarteraCobro: this.IdCarteraCobro,
        },
      })

      if (plan && plan.length > 0) {
        this.editable = 0
        this.pagos = plan.map( p => ({
          fecha : parseDate( p.fechaPago.slice(0, 10), true ),
          importe : new Dinero( { amount: p.importe * 100, currency : 'EUR' } ),
          editable : p.editable,
          pagado : p.pagado,
          IdPago : p.IdPago
        }) )
      }

      this.data = parseImpagados([data])[0];
    },
  },
  mounted() {
    this.getDatos();
  },
};
</script>
